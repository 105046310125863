import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-cedar-rapids-iowa.png'
import image0 from "../../images/cities/scale-model-of-cedar-rapids-museum-of-art-in-cedar-rapids-iowa.png"
import image1 from "../../images/cities/scale-model-of-tait-cummins-sports-complex-in-cedar-rapids-iowa.png"
import image2 from "../../images/cities/scale-model-of-cedar-rapids-tourism-office-in-cedar-rapids-iowa.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Cedar Rapids'
            state='Iowa'
            image={image}
            lat='41.9778795'
            lon='-91.66562320000003'
            attractions={ [{"name": "Cedar Rapids Museum of Art", "vicinity": "410 3rd Ave SE, Cedar Rapids", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.97886099999999, "lng": -91.66431}, {"name": "Tait Cummins Sports Complex", "vicinity": "3000 C St SW, Cedar Rapids", "types": ["point_of_interest", "establishment"], "lat": 41.9466284, "lng": -91.63988890000002}, {"name": "Cedar Rapids Tourism Office", "vicinity": "370 1st Avenue East, Cedar Rapids", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.9800083, "lng": -91.66644309999998}] }
            attractionImages={ {"Cedar Rapids Museum of Art":image0,"Tait Cummins Sports Complex":image1,"Cedar Rapids Tourism Office":image2,} }
       />);
  }
}